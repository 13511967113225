const pendingStatuses = [0, 18, 1, 2, 15, 16, 17]

const activeStatuses = [3, 4, 5, 6, 8, 19]

const completedStatuses = [7, 9, 10, 11, 12, 13]

const canceledStatuses = [14]

export default function showShipmentPrice(shipment) {
  if (!shipment) return null

  if (shipment.is_hourly_based) {
    if (
      pendingStatuses.includes(shipment.status) ||
      activeStatuses.includes(shipment.status) ||
      canceledStatuses.includes(shipment.status)
    ) {
      const price = parseFloat(shipment.quote_result?.rate_raw || 0)

      return shipment.quote_result?.rate_raw
        ?`${price.toLocaleString("en-US", { style: "currency", currency: "USD" })}/hr`
        : null
    }

    if (completedStatuses.includes(shipment.status)) {
      const price = parseFloat(shipment.quote_result?.carrier_cut || 0)

      return shipment.quote_result?.carrier_cut
        ? price.toLocaleString("en-US", { style: "currency", currency: "USD" })
        : null
    }

    return null
  }

  const price = parseFloat(shipment.quote_result?.carrier_cut || 0)

  return shipment.quote_result?.carrier_cut
    ? price.toLocaleString("en-US", { style: "currency", currency: "USD" })
    : null
}
